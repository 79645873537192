import React, {useEffect} from 'react';
import {Container, Grid} from "@mui/material";

import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import Planning from "../../components/GettikCaseComponents/Planning/Planning";
import GettikInfo from "../../components/GettikCaseComponents/GettikInfo/GettikInfo";
import MainFeatures from "../../components/GettikCaseComponents/MainFeatures/MainFeatures";
import ContactUs from "../../components/ContactUs/ContactUs";
import GettikStack from "../../components/GettikCaseComponents/GettikStack/GettikStack";
import GettikReviews from "../../components/GettikCaseComponents/GettikReviews/GettikReviews";
import Timeline from "../../components/Timeline/Timeline";

import './styles.css';
import bgImg from "../../assets/images/Gettik_bg.png";
import playMarket from "../../assets/images/play-market.svg"
import appStore from "../../assets/images/ios.svg"
import gettikTimeline from "../../assets/images/gettik-timeline.svg"
import carIcon from '../../assets/images/car-icon.svg'


const GettikCase = () => {

    useEffect(() => {
        window.scroll(0,0);
    }, []);


    const textArr = [
        {
            title: "Project idea",
            text: "Gettik is a ridesharing service such as BlaBlaCar that connects riders with drivers that can meet specific needs.",
            id: 0,
        },
        {
            title: "Target audience",
            text: "Seamless experiences across devices are non-negotiable. Our expertise guarantees designs that adapt flawlessly to desktops, tablets, and smartphones.",
            id: 1,
        },
        {
            title: "Project challenges",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 2,
        },
        {
            title: "Results",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 3,
        }
    ];


    const timelineArr = [
        {id:1, title: "Time", text: "6 month"},
        {id:2, title: "Team", text: "5"},
        {id:3, title: "Platform", text: "IOS, Android"},
        {id:4, title: "Type", text: "Map"},
        {id:5, title: "Industry", text: "Transportation"},
    ]

    return (
        <div className="gettik-case">
            <div className="gettik-case-main-block"
                 style={{
                     backgroundImage: `url(${bgImg})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'top 30%',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <img src={"/images/gettik-phone-cropped.svg"} alt={"gettik-app-img"} className="gettik-phone-img"/>
                    <h2 className="gettik-case-main-title-1">Ride sharing</h2>
                    <h2 className="gettik-case-main-title-2">Solution</h2>

                    <Grid container justifyContent="flex-end">
                        <div className="gettik-case-estimate">
                            <p>
                                Transportation app that connects drivers with passengers on long-distance travel
                            </p>
                            <button className="styled-button">
                                Estimate your project
                            </button>
                        </div>
                    </Grid>
                </Container>
            </div>
            <Timeline arr={timelineArr} img={gettikTimeline} icon={carIcon}/>
            <div style={{backgroundColor: "#F6F6F6"}}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gettik-changeable-box">
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <h2 className=" gettik-changeable-box-title">Gettik</h2>
                        </div>
                        <p className="gettik-changeable-box-text">Trusted ridesharing community</p>
                        <div className="change-block-wrapper">
                            <ChangeableTextBlock textArr={textArr} type={"gettik-case"}/>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <img src={playMarket} alt="play-market" className="play-market"/>
                            <img src={appStore} alt="app-market"  className="app-market"/>
                        </div>
                    </div>
                </Container>
            </div>
            <Planning/>
            <GettikInfo/>
            <MainFeatures/>
            <GettikStack/>
            <GettikReviews/>
            <div style={{backgroundColor: "#161616", paddingTop:"62px"}}>
                <ContactUs/>
            </div>
        </div>
    );
};

export default GettikCase;