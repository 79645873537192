import React from 'react';
import arrow from '../../assets/images/arrow-right.svg'
import ux from '../../assets/images/ux.png'
import mobile from '../../assets/images/mobile.png'
import { useMediaQuery } from 'react-responsive';
import {Container} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Services = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 960});

    return (
        <div className={"services"}>
            <h2 className='block-title'>Services</h2>
            <div className='web'>
                <img className="web-img" src={"/images/web.svg"} alt="web"/>
                <h2>development</h2>
                <button className='read-more-btn' onClick={()=>navigate('/web')}>Read more <img src={arrow} alt="arrow"/></button>
            </div>
            <div style={{backgroundColor:"white", padding:"14px 0 10px"}}>
                {isMobile ? (
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="ux-mobile">
                            <img className="ux-img-mob" src={ux} alt="ux design"/>
                            <h2 className={'bigger-gradient'}>UX/UI design</h2>
                            <button className='read-more-btn'>Read more <img src={arrow} alt="arrow"/></button>
                        </div>
                    </Container>
                ):(
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="ux">
                            <h2>UX/UI design</h2>
                            <img className="ux-img" src={ux} alt="ux design"/>
                            <button className='read-more-btn' >Read more <img src={arrow} alt="arrow"/></button>
                        </div>
                    </Container>
                )}
            </div>
            <div className="mobile">
                <h2>Mobile development</h2>
                <button className='read-more-btn' onClick={()=>navigate('/mobile')}>Read more<img src={arrow} alt="arrow" style={{marginLeft:"10px"}}/></button>
                <img src={mobile} alt="mobile" className="mobile-dev-pic"/>
            </div>
        </div>
    );
};

export default Services;