import React from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";


import './styles.css'
import meiliSearch from '../../../assets/images/meiliSearch.svg'
import seo from '../../../assets/images/SEO.svg'
import arrow from '../../../assets/images/elmart-feature-arrow.svg'
import circles from '../../../assets/images/elmart-circles.svg'

const ElmartFeatures = () => {
    const max730 = useMediaQuery({maxWidth: 730})
    return (
        <div>
            <div className="elmart-feature-1">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <h2>Main features of the project</h2>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="elmart-feature-box-1">
                                <h3 className="elmart-feature-title">MeiliSearch Integration</h3>
                                <p style={{marginBottom: "42px"}}>Utilizes MeiliSearch for its powerful, fast, and
                                    intuitive search capabilities,
                                    allowing for dynamic filtering and efficient multi-venue searches.
                                </p>
                                <p><span>Impact:</span> Enhances user experience by enabling precise and quick search
                                    results,
                                    making it easier for users to find exactly what they need</p>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                            <div className="elmart-feature-img-wrapper"
                                 style={{
                                     backgroundImage: `url(${meiliSearch})`,
                                     backgroundSize: 'contain',
                                     backgroundPosition: 'center',
                                     backgroundRepeat: "no-repeat",
                                 }}
                            >
                                <img src={"/images/elmart-feature-1.svg"} alt="elmart-screen" className="elmart-feature-img"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="elmart-feature-2"
                 style={{
                     backgroundImage: `url(${seo})`,
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    {max730 ? (
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                                <div className="elmart-feature-box-2">
                                    <h3 className="elmart-feature-title">SEO Optimization</h3>
                                    <p style={{marginBottom: "42px"}}>Elmart employs advanced SEO strategies facilitated
                                        by
                                        NEXT JS, ensuring the platform
                                        ranks well on search engines and reaches a broad audience.
                                    </p>
                                    <p><span>Impact:</span> Increases platform visibility and user acquisition, crucial
                                        for
                                        both buyers and
                                        sellers in the B2B marketplace</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                                <div className="elmart-feature-2-img-wrapper">
                                    <img src={"/images/elmart-feature-2.svg"} alt="elmart-screen" className="elmart-feature-2-img"/>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justifyContent="space-between" alignItems="center">


                            <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                                <div className="">
                                    <img src={"/images/elmart-feature-2.svg"} alt="elmart-screen" className="elmart-feature-2-img"/>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                                <div className="elmart-feature-box-2">
                                    <h3 className="elmart-feature-title">SEO Optimization</h3>
                                    <p style={{marginBottom: "42px"}}>Elmart employs advanced SEO strategies facilitated
                                        by
                                        NEXT JS, ensuring the platform
                                        ranks well on search engines and reaches a broad audience.
                                    </p>
                                    <p><span>Impact:</span> Increases platform visibility and user acquisition, crucial
                                        for
                                        both buyers and
                                        sellers in the B2B marketplace</p>
                                </div>
                            </Grid>
                        </Grid>

                    )}
                </Container>
            </div>
            <div className="elmart-feature-1"
                 style={{
                     backgroundImage: `url(${arrow})`,
                     backgroundSize: '55%',
                     backgroundPosition: 'left 88%',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="elmart-feature-box-1">
                                <h3 className="elmart-feature-title">Optimized Handling
                                    of High Request Volumes</h3>
                                <p style={{marginBottom: "42px"}}>The platform is engineered to manage high volumes of
                                    user requests smoothly, ensuring
                                    consistent performance and reliability.
                                </p>
                                <p><span>Impact:</span> Guarantees a seamless and responsive experience for all users,
                                    crucial for
                                    maintaining satisfaction and engagement, even during peak traffic times</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                            <div className="elmart-feature-img-wrapper"
                            >
                                <img src={"/images/elmart-feature-3.svg"} alt="elmart-screen" className="elmart-feature-img"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="elmart-feature-2 elmart-feature-3"
                 style={{
                     backgroundImage: `url(${circles})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: '40%',
                     backgroundPosition: '78% center',
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    {max730 ? (
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                                <div className="elmart-feature-box-1">
                                    <h3 className="elmart-feature-title">SEO Optimization</h3>
                                    <p style={{marginBottom: "42px"}}>Elmart employs advanced SEO strategies facilitated
                                        by
                                        NEXT JS, ensuring the platform
                                        ranks well on search engines and reaches a broad audience.
                                    </p>
                                    <p><span>Impact:</span> Increases platform visibility and user acquisition, crucial
                                        for
                                        both buyers and
                                        sellers in the B2B marketplace</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                                <div className="elmart-feature-2-img-wrapper">
                                    <img src={"/images/elmart-feature-4.svg"} alt="elmart-screen" className="elmart-feature-4-img"/>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justifyContent="space-between" alignItems="center">


                            <Grid item xs={12} sm1={5.8} md={5} lg={5.8}>
                                <div style={{display:"flex", justifyContent:"flex-start"}}>
                                    <img src={"/images/elmart-feature-4.svg"} alt="elmart-screen" className="elmart-feature-4-img"/>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm1={6.2} md={7} lg={6.2} style={max730 ? {marginBottom: "32px"} : {}}>
                                <div className="elmart-feature-box-1">
                                    <h3 className="elmart-feature-title">SEO Optimization</h3>
                                    <p style={{marginBottom: "42px"}}>Elmart employs advanced SEO strategies facilitated
                                        by
                                        NEXT JS, ensuring the platform
                                        ranks well on search engines and reaches a broad audience.
                                    </p>
                                    <p><span>Impact:</span> Increases platform visibility and user acquisition, crucial
                                        for
                                        both buyers and
                                        sellers in the B2B marketplace</p>
                                </div>
                            </Grid>
                        </Grid>

                    )}
                </Container>
            </div>

        </div>
    );
};

export default ElmartFeatures;