import React, {useEffect, useState} from 'react';
import {Container} from "@mui/material";
import screen1 from '../../assets/images/screen1.png'
import screen2 from '../../assets/images/screen2.png'
import screen3 from '../../assets/images/screen3.png'
import screen4 from '../../assets/images/screen4.png'
import screen5 from '../../assets/images/screen5.png'
import arrow from '../../assets/images/arrow-right.svg'
import {useMediaQuery} from "react-responsive";
import CaseSocialSlider from "./CaseSocialSlider";
import SliderMobile from "../SliderMobile/SliderMobile";
import {useNavigate} from "react-router-dom";

const CaseStudySocial = () => {
    const navigate = useNavigate()
    const [animate, setAnimate] = useState(false);

    const bigScreen = useMediaQuery({minWidth: 1000});
    const isMobile = useMediaQuery({minWidth: 600});
    const mobile = useMediaQuery({maxWidth: 872});

    const [scrollPos, setScrollPos] = useState('0');

    useEffect(() => {
        if (!mobile) {
            const handleScroll = () => {
                const currentScrollPos =
                    window.scrollY || document.documentElement.scrollTop;

                if (currentScrollPos > scrollPos) {
                    // Scrolling down
                    const screen2 = document.getElementById("screen-2");
                    const screen4 = document.getElementById("screen-4");
                    const screen1 = document.getElementById("screen-1");
                    const screen3 = document.getElementById("screen-3");
                    const screen5 = document.getElementById("screen-5");

                    if (screen2 && screen4 && screen1 && screen3 && screen5) {
                        screen2.style.top = "100%";
                        screen4.style.top = "100%";
                        screen1.style.top = "0";
                        screen3.style.top = "0";
                        screen5.style.top = "0";
                    }
                } else {
                    // Scrolling up
                    const screen2 = document.getElementById("screen-2");
                    const screen4 = document.getElementById("screen-4");
                    const screen1 = document.getElementById("screen-1");
                    const screen3 = document.getElementById("screen-3");
                    const screen5 = document.getElementById("screen-5");

                    if (screen2 && screen4 && screen1 && screen3 && screen5) {
                        screen2.style.top = "0";
                        screen4.style.top = "0";
                        screen1.style.top = "100%";
                        screen3.style.top = "100%";
                        screen5.style.top = "100%";
                    }
                }

                setScrollPos(currentScrollPos);
            };

            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [scrollPos]);


    return (
        <div>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className={'case-social'}>
                    <div className="gradient-title-wrapper">
                        <h2 className='gradient' style={{marginTop: "55px", marginBottom: "25px"}}>Case study</h2>
                    </div>
                    <p className="case-social-text">Social network and radio stream</p>

                    {!mobile && (
                        <div className="case-social-box">
                            <div className={`screen-animation-box ${animate ? 'animate' : ''}`}>
                                <div className="screen-box screen-1" id="screen-1">
                                    <img src={screen1} alt="screen1"/>
                                </div>
                                <div className="screen-box screen-2" id="screen-2">
                                    <img src={screen2} alt="screen2"/>
                                </div>
                                <div className="screen-box screen-3" id="screen-3">
                                    <img src={screen3} alt="screen3"/>
                                </div>
                                <div className="screen-box screen-4" id="screen-4">
                                    <img src={screen4} alt="screen4"/>
                                </div>
                                <div className="screen-box screen-5" id="screen-5">
                                    <img src={screen5} alt="screen5"/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
            {mobile && (
                <div style={{position: "relative"}}>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className='case-social-back'>
                        </div>
                    </Container>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                        <CaseSocialSlider/>
                    </Container>
                </div>
            )}
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="case-social-bottom">
                    <div className="case-social-bottom-box">
                        <p className="case-social-description">
                            A platform for equestrian enthusiasts and businesses to promote their products and services
                        </p>

                        <button className="read-more-btn" onClick={() => navigate('/sport-horse-case')}>Read more <img
                            src={arrow} alt="arrow"/></button>
                    </div>
                </div>
            </Container>
        </div>

    );
};

export default CaseStudySocial;