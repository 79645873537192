import React, {useEffect, useRef, useState} from 'react';
import {Container, Grid} from "@mui/material";
import usaid from '../../assets/images/usaid.png';
import undp from '../../assets/images/undp.png';
import giz from '../../assets/images/giz.png';
import gps from '../../assets/images/gps.png';
import neighbor from '../../assets/images/good-neighbors.png';
import bank from '../../assets/images/bank.png';
import sos from '../../assets/images/sos.png';
import jica from '../../assets/images/jica.png';
import selva from '../../assets/images/selva.png';
import kivano from '../../assets/images/kivano.png';
import internews from '../../assets/images/internews.png';
import auca from '../../assets/images/auca.png';
import international from '../../assets/images/international.png';
import sport from '../../assets/images/sporthorse.png';
import institute from '../../assets/images/institute.png';
import east from '../../assets/images/east.png';
import {useMediaQuery} from "react-responsive";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Controller, Navigation, Pagination} from "swiper";
import 'swiper/swiper.min.css';
import './styles.css'


SwiperCore.use([Pagination, Navigation, Autoplay, Controller]);


const Clients = () => {

        const mobile = useMediaQuery({maxWidth: 1180})

        const swiper1Ref = useRef(null);
        const swiper2Ref = useRef(null);
        const swiper3Ref = useRef(null);


        const [controlledSwiper, setControlledSwiper] = useState(null);
        const [swiper2, setSwiper2] = useState(null);
        const [swiper3, setSwiper3] = useState(null);

        const [swiperId, setId] = useState("")

        const [changed, setChange] = useState(false)

        console.log(swiperId)

        useEffect(() => {


            if (controlledSwiper && swiper2 && swiper3) {

                if (swiperId === "firstSwiper") {
                    controlledSwiper.controller.control = [swiper2, swiper3];
                } else if (swiperId === "secondSwiper") {
                    swiper2.controller.control = [controlledSwiper, swiper3];
                } else if (swiperId === "thirdSwiper") {
                    swiper3.controller.control = [controlledSwiper, swiper2];
                }

            }

        }, [swiperId, changed]);


        const handleTouch = () => {
            if (controlledSwiper && swiper2 && swiper3) {
                controlledSwiper.controller.control = null;
                swiper2.controller.control = null;
                swiper3.controller.control = null;
            }
            setChange(!changed)
        };


        return (
            <Container maxWidth={false} sx={{maxWidth: "1284px", marginBottom: "32px", padding: "10px 0 36px "}}>
                <h2 className="block-title" style={{marginBottom: "12px"}}>Clients</h2>

                <p className='clents-text'>We take pride in working with companies of all sizes and across various
                    industries, providing tailored IT solutions. Our clients trust us with key projects, and we always
                    strive to exceed their expectations.</p>

                {mobile ? (
                    <div style={{marginBottom: "62px"}}>

                        <div className="client-slider-container" id="firstSwiper"
                             onTouchStart={(e) => setId(e.currentTarget.id)}
                             onTouchEnd={() => handleTouch()}
                        >
                            <Swiper
                                ref={swiper1Ref}
                                loop={true}
                                initialSlide={2}
                                grabCursor={true}
                                centeredSlides={false}

                                slidesPerView={1.9}
                                spaceBetween={10}
                                pagination={{el: '.swiper-pagination', clickable: true}}
                                modules={[Pagination, Navigation, Controller]}
                                className="my_swiper_clients"
                                onSwiper={setControlledSwiper}

                                breakpoints={{
                                    // when window width is >= 480px
                                    360: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 10

                                    },
                                    400: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 10

                                    },

                                    490: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 10
                                    },
                                    520: {
                                        slidesPerView: 3.1,
                                        spaceBetween: 10
                                    },

                                    540: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 20
                                    },

                                    650: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 20,
                                    },
                                    750: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 10,
                                    },
                                    910: {
                                        slidesPerView: 3.6,
                                        spaceBetween: 10,
                                    },


                                }}
                            >
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={international} alt="international"/>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={east} alt="east"/>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={usaid} alt="usaid"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={giz} alt="giz"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={gps} alt="gps"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={international} alt="international"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={east} alt="east"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={usaid} alt="usaid"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={giz} alt="giz"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={gps} alt="gps"/>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="client-slider-container2" id="secondSwiper"
                             onTouchStart={(e) => setId(e.currentTarget.id)}
                             onTouchEnd={() => handleTouch()}
                        >
                            <Swiper
                                ref={swiper2Ref}
                                initialSlide={2}
                                grabCursor={false}
                                loop={true}

                                centeredSlides={true}
                                slidesPerView={1.9}
                                spaceBetween={10}
                                pagination={{el: '.swiper-pagination', clickable: true}}
                                modules={[Pagination, Navigation, Controller]}
                                onSwiper={setSwiper2}
                                className="my_swiper2_clients"
                                breakpoints={{
                                    // when window width is >= 480px
                                    360: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 10

                                    },
                                    400: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 10

                                    },


                                    490: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 10
                                    },
                                    520: {
                                        slidesPerView: 3.1,
                                        spaceBetween: 10
                                    },

                                    540: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 20
                                    },

                                    650: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 20,
                                    },
                                    750: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 10,
                                    },
                                    910: {
                                        slidesPerView: 3.6,
                                        spaceBetween: 10,
                                    },

                                }}
                            >
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={sport} alt="sos" style={{maxWidth: "60px"}}/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={institute} alt="institute"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={neighbor} alt="neighbor"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={bank} alt="bank"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={sos} alt="sos"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={sport} alt="sos" style={{maxWidth: "60px"}}/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={institute} alt="institute"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={neighbor} alt="neighbor"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={bank} alt="bank"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={sos} alt="sos"/>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div
                            className="client-slider-container3"
                            id="thirdSwiper"
                            onTouchStart={(e) => setId(e.currentTarget.id)}
                            onTouchEnd={() => handleTouch()}
                        >

                            <Swiper
                                initialSlide={2}
                                ref={swiper3Ref}
                                loop={true}
                                grabCursor={false}
                                centeredSlides={false}
                                // allowSlideNext={false}
                                // allowSlidePrev={false}
                                slidesPerView={1.9}
                                spaceBetween={10}
                                pagination={{el: '.swiper-pagination', clickable: true}}
                                modules={[Pagination, Navigation, Controller]}
                                onSwiper={setSwiper3}
                                className="my_swiper3_clients"
                                breakpoints={{
                                    // when window width is >= 480px
                                    360: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 10

                                    },
                                    400: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 10

                                    },


                                    490: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 10
                                    },

                                    520: {
                                        slidesPerView: 3.1,
                                        spaceBetween: 10
                                    },

                                    540: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 20
                                    },

                                    650: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 20,
                                    },
                                    750: {
                                        slidesPerView: 3.4,
                                        spaceBetween: 10,
                                    },
                                    910: {
                                        slidesPerView: 3.6,
                                        spaceBetween: 10,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={internews} alt="internews"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={auca} alt="auca"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={jica} alt="jica" style={{maxWidth: '80px'}}/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={selva} alt="selva"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={kivano} alt="kivano"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={internews} alt="internews"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={auca} alt="auca"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={jica} alt="jica" style={{maxWidth: '70px'}}/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={selva} alt="selva"/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="clients-logo-wrapper">
                                        <img src={kivano} alt="kivano"/>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>

                ) : (
                    <Grid container flexWrap={'wrap'}
                          sx={{justifyContent: {xs: "center", sm: "space-evenly", md: "space-between"}}}
                    >
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={usaid} alt="usaid"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={undp} alt="undp"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={giz} alt="giz"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={gps} alt="gps"/>
                            </div>
                        </Grid>

                        {/*second row*/}
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={neighbor} alt="neighbor"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={bank} alt="bank"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={sos} alt="sos"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={jica} alt="jica"/>
                            </div>
                        </Grid>

                        {/*third row*/}
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={selva} alt="selva"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={kivano} alt="kivano"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={internews} alt="internews"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={auca} alt="auca"/>
                            </div>
                        </Grid>

                        {/*forth row*/}
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={international} alt="international"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={sport} alt="sport"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={institute} alt="institute"/>
                            </div>
                        </Grid>
                        <Grid item xs={3.8} sm={3.8} md={2.8}>
                            <div className='logo-wrapper'>
                                <img src={east} alt="east"/>
                            </div>
                        </Grid>
                    </Grid>
                )}

            </Container>
        );
    }
;

export default Clients;