import React from 'react';
import {Container, Grid} from "@mui/material";

import './styles.css';
import feature1 from '../../../assets/images/sporthorse-feature-bg-1.png'


const Feature1 = () => {

    return (
        <div className="features">
            <Container maxWidth={false} sx={{maxWidth: "1684px"}} disableGutters={true}>
                <Grid container>
                    <Grid item xs={12} sm1={6}>
                        <div className="feature-white-half">
                            <h2 className="sporthorse-feature-main-title">
                                Main features of the project
                            </h2>

                            <div className="feature-white">
                                <h2 className="feature-black-title">Shopify Integration</h2>
                                <p className="feature-black-text">Seamless integration with Shopify enables Sporthorse
                                    to offer an extensive e-commerce
                                    platform to businesses. This feature allows for easy management of product listings,
                                    inventory, and sales directly within the Sporthorse ecosystem.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm1={6}>
                        <div
                            style={{
                                backgroundImage: `url(${feature1})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >

                            <img src={"/images/sporthorse-feature-screen-1.svg"} alt="sporthorse-app-screen" className="feature-screen-img"/>

                        </div>
                    </Grid>

                </Grid>
            </Container>


        </div>
    );
};

export default Feature1;