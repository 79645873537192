import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import OshbusPlanning from "../../components/OshbusComponents/OshbusPlanning/OshbusPlanning";
import OshbusMarket from "../../components/OshbusComponents/OshbusMarket/OshbusMarket";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import Timeline from "../../components/Timeline/Timeline";

import oshbusTimeline from "../../assets/images/oshbus-timeline.svg";
import busIcon from "../../assets/images/bus.svg";
import playMarket from "../../assets/images/play-market.svg";
import appStore from "../../assets/images/ios.svg";

import './styles.css';
import OshbusFeatures from "../../components/OshbusComponents/OshbusFeatures/OshbusFeatures";
import OshbusStack from "../../components/OshbusComponents/OshbusStack/OshbusStack";
import GettikReviews from "../../components/GettikCaseComponents/GettikReviews/GettikReviews";
import ContactUs from "../../components/ContactUs/ContactUs";


const Oshbus = () => {
    const max480 = useMediaQuery({maxWidth: 480});

    const timelineArr = [
        {id: 1, title: "Time", text: "6 month"},
        {id: 2, title: "Team", text: "5"},
        {id: 3, title: "Platform", text: "IOS, Android"},
        {id: 4, title: "Type", text: "Map"},
        {id: 5, title: "Industry", text: "Transportation"},
    ]

    const textArr = [
        {
            title: "Project idea",
            text: "OshBus aims to enhance the public bus transportation experience in Osh with real-time tracking, optimal route suggestions, and detailed schedule and stop information, catering to residents and visitors alike.",
            id: 0,
        },
        {
            title: "Target audience",
            text: "Seamless experiences across devices are non-negotiable. Our expertise guarantees designs that adapt flawlessly to desktops, tablets, and smartphones.",
            id: 1,
        },
        {
            title: "Project challenges",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 2,
        },
        {
            title: "Results",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 3,
        }
    ];

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <>
            <div className="oshbus-case-main"
                 style={{
                     backgroundImage: `url('/images/oshbus-main-img.svg')`,
                     backgroundSize: `${max480 ? "80%" : "62%"}`,
                     backgroundPosition: 'right bottom',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <div className="oshbus-case-block">

                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="oshbus-main-wrapper">
                            <h2 className="oshbus-case-title">Real-time shuttle bus monitoring</h2>
                            <p>A handy application for monitoring route
                                transport in real time</p>
                            <button className="styled-button">Estimate your project</button>
                        </div>
                    </Container>

                </div>

            </div>
            <Timeline arr={timelineArr} img={oshbusTimeline} icon={busIcon}/>
            <div style={{backgroundColor: "#F6F6F6"}}>
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    <div className="gettik-changeable-box">
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <h2 className="changeable-box-oshbus-title">Oshbus</h2>
                        </div>
                        <p className="gettik-changeable-box-text">Real-time shuttle bus monitoring</p>
                        <div className="change-block-wrapper">
                            <ChangeableTextBlock textArr={textArr} type={"gettik-case"}/>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <img src={playMarket} alt="play-market" className="play-market"/>
                            <img src={appStore} alt="app-market" className="app-market"/>
                        </div>
                    </div>
                </Container>
            </div>
            <OshbusPlanning/>
            <OshbusMarket/>
            <OshbusFeatures/>
            <OshbusStack/>
            <GettikReviews/>
            <div style={{backgroundColor: "#161616", paddingTop:"62px"}}>
                <ContactUs/>
            </div>
        </>
    );
};

export default Oshbus;