import React, {useEffect} from 'react';
import {Container, Grid} from "@mui/material";

import Timeline from "../../components/Timeline/Timeline";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import SporthorsePlanning from "../../components/SporthorseComponents/SporhorsePlanning/SporthorsePlanning";
import ProjectScreens from "../../components/SporthorseComponents/ProjectScreens/ProjectScreens";
import Feature1 from "../../components/SporthorseComponents/Feature1/Feature-1";
import DarkFeature1 from "../../components/SporthorseComponents/DarkFeature1/DarkFeature1";
import DarkFeature2 from "../../components/SporthorseComponents/DarkFeature2/DarkFeature2";
import Feature2 from "../../components/SporthorseComponents/Feature2/Feature2";
import SporthorseStack from "../../components/SporthorseComponents/SporthorseStack/SporthorseStack";
import GettikReviews from "../../components/GettikCaseComponents/GettikReviews/GettikReviews";
import ContactUs from "../../components/ContactUs/ContactUs";


import bgImg from "../../assets/images/horse.png";
import horseTimeline from "../../assets/images/horse-timeline.svg";
import horseIcon from "../../assets/images/horse-icon.svg";
import playMarket from "../../assets/images/play-market.svg";
import appStore from "../../assets/images/ios.svg";
import './styles.css';



const SportHorseCase = () => {
    useEffect(() => {
        window.scroll(0,0);
    }, []);

    const timelineArr = [
        {id: 1, title: "Time", text: "6 month"},
        {id: 2, title: "Team", text: "5"},
        {id: 3, title: "Platform", text: "IOS, Android"},
        {id: 4, title: "Type", text: "Map"},
        {id: 5, title: "Industry", text: "Transportation"},
    ]

    const textArr = [
        {
            title: "Project idea",
            text: "Gettik is a ridesharing service such as BlaBlaCar that connects riders with drivers that can meet specific needs.",
            id: 0,
        },
        {
            title: "Target audience",
            text: "Seamless experiences across devices are non-negotiable. Our expertise guarantees designs that adapt flawlessly to desktops, tablets, and smartphones.",
            id: 1,
        },
        {
            title: "Project challenges",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 2,
        },
        {
            title: "Results",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 3,
        }
    ];

    return (
        <>
            <div className="sport-horse-block">
                <div
                    className="bg-image-box"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                    }}
                >
                    <Container maxWidth={false} sx={{maxWidth: "1284px", height: "100%"}}>
                        <div className="sport-horse-inner-block">
                            <Grid container justifyContent="flex-end">
                                <div className="sport-horse-text-wrapper">
                                    <h2 className="">Super-app for equestrians</h2>
                                    <p>
                                        A platform for equestrian enthusiasts and businesses to promote their products
                                        and
                                        services
                                    </p>
                                    <button className="styled-button">
                                        Estimate your project
                                    </button>
                                </div>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </div>
            <Timeline arr={timelineArr} img={horseTimeline} icon={horseIcon}/>
            <div
                style={{
                    backgroundImage: `url('/images/horse-herd.svg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height:"242px"
            }}
            >
            </div>
            <div className="dark-changeable-text">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gettik-changeable-box">
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <h2 className="changeable-box-title">Sporthorse</h2>
                        </div>
                        <p className="changeable-box-text">Equestrian enthusiasts platform</p>
                        <div className="change-block-wrapper">
                            <ChangeableTextBlock textArr={textArr} type={"gettik-case"} color="dark"/>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <img src={playMarket} alt="play-market" className="play-market"/>
                            <img src={appStore} alt="app-market"  className="app-market"/>
                        </div>
                    </div>
                </Container>
            </div>
            <SporthorsePlanning/>
            <ProjectScreens/>
            <Feature1/>
            <DarkFeature1/>
            <DarkFeature2/>
            <Feature2/>
            <SporthorseStack/>
            <GettikReviews/>
            <div style={{backgroundColor: "#161616", paddingTop:"62px"}}>
                <ContactUs/>
            </div>
        </>
    );
};

export default SportHorseCase;