import React from 'react';
import {Container} from "@mui/material";


import elmartLogo from "../../../assets/images/elmartLogo.svg";
import './styles.css'


const PlayMarket = () => {
    return (
        <div className="elmart-play-market">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="elmart-play-inner">
                    <h2>Multi-Platform Development</h2>
                    <img src={"/images/elmart-image.svg"} alt="elmart" className="elmart-img"/>

                    <div className="elmart-store-wrapper">
                        <div className="elmart-logo-wrapper">
                            <img src={elmartLogo} alt="elmart-logo"/>
                            <p>Elmart</p>
                        </div>
                        <p className="elmart-available-on">Now available on</p>
                        <p className="elmart-store-text">Google Play and AppStore</p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PlayMarket;