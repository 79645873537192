import React from 'react';
import {Container} from "@mui/material";
import arrow from '../../assets/images/arrow-right.svg';
import {useMediaQuery} from "react-responsive";
import CaseMarketSlider from "./CaseMarketSlider";
import {useNavigate} from "react-router-dom";

const CaseStudyMarket = () => {
    const navigate = useNavigate();
    const mobile = useMediaQuery({maxWidth: 900});

    return (
        <div className="case-market">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className="block-title">Case Study</h2>
                <div className="case-market-inner">
                    <div className="market-content">
                        <h3 className="bigger-gradient" style={{marginTop: "22px"}}>Marketplace</h3>
                        <p>A cutting-edge online platform for posting free ads, shopping, and arranging courier
                            deliveries, seamlessly integrating classifieds and a marketplace.</p>
                        <button className="read-more-btn" onClick={() => navigate('/elmart-case')}>Read more <img
                            src={arrow} alt="arrow"/></button>
                    </div>
                </div>
            </Container>
            {mobile && (
                <div style={{position: "relative"}}>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="case-market-slider">
                        </div>
                    </Container>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                        <CaseMarketSlider/>
                    </Container>
                </div>
            )}
        </div>
    );
};

export default CaseStudyMarket;