import React from 'react';
import {Container} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import SliderMobile from "../../SliderMobile/SliderMobile";
import oshbusLogo from "../../../assets/images/OshBusLogo.png";
import GooglePlayIcon from "../../../assets/images/google_play_icon.svg";
import './styles.css';


const OshbusMarket = () => {

    const max730 = useMediaQuery({maxWidth: 730});

    return (
        <>
            {max730 ? (
                <div className="oshbus-slider-box">
                    <div style={{position: "relative", marginTop: "2px"}}>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                            <div className="case-transport-slider" style={{marginBottom: "5px"}}>
                            </div>
                        </Container>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                            <SliderMobile/>
                        </Container>
                    </div>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="oshbus-market-text-wrapper">
                            <div>
                                <p className="oshbus-available-on">Now available on</p>
                                <div className="info-box-white">
                                    <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                    <h3 className="oshbus-store-text">Google Play and AppStore</h3>
                                </div>
                            </div>
                                <img src={oshbusLogo} alt="elmart-logo" className="oshbus-logo"/>
                        </div>
                    </Container>
                </div>
            ) :(
                <div className="oshbus-market"
                     style={{
                         backgroundImage: `url('/images/oshbus-screens.svg')`,
                         backgroundSize: 'contain',
                         backgroundPosition: 'right center',
                         backgroundRepeat: "no-repeat",
                     }}
                >
                    <Container maxWidth={false} sx={{maxWidth: "1284px", height:"100%", display:"flex"}}>
                        <div className="oshbus-store-wrapper">
                            <div className="oshbus-logo-wrapper">
                                <img src={oshbusLogo} alt="elmart-logo"/>
                            </div>
                            <p className="oshbus-available-on ">Now available on</p>
                            <div className="info-box-white">
                                <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                <h3 className="oshbus-store-text">Google Play and AppStore</h3>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

export default OshbusMarket;